export const smoothScroller = {
  methods: {
    smoothScrollToElement (el, offset = 0, delayInMs = 0) {
      const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
      const top = el.offsetTop - offset;
      setTimeout(() => {
        supportsNativeSmoothScroll ? window.scroll({ top, behavior: 'smooth' }) : this.polyfillScroll(top);
      }, delayInMs);
    },
    polyfillScroll (to, duration = 600) {
      const element = document.scrollingElement || document.documentElement;
      const start = element.scrollTop;
      const change = to - start;
      const startDate = +new Date();

      const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        element.scrollTop = parseInt(this.easeInOutQuad(currentTime, start, change, duration));
        if(currentTime < duration) {
          requestAnimationFrame(animateScroll);
        }
        else {
          element.scrollTop = to;
        }
      };

      animateScroll();
    },
    // t = current time, b = start value, c = change in value, d = duration
    easeInOutQuad (t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    },
  },
};
