<template lang="pug">
  .relative(v-clickoutside='close' @keydown.esc='close')
    a(href="#" @click.prevent="isOpen = !isOpen" :class="targetCustomClasses")
      slot
    .transform.z-10.transition-all.ease-out.duration-300(
      ref="dropdown"
      :class="isOpen ? 'block lg:visible' : 'hidden lg:block lg:invisible'"
      class="lg:pt-2 lg:absolute lg:top-full lg:left-1/2 lg:-translate-x-1/2"
    )
      .transform.transition-all.ease-out.duration-300(
        :class="[openedClass, narrowClass, customClasses]"
      )
        slot(name='dropdown')
</template>

<script>
export default {
  name: 'GenericSubNav',
  props: {
    narrow: {
      type: Boolean,
      default: false,
    },
    targetCustomClasses: {
      type: String,
      default: '',
    },
    customClasses: {
      type: String,
      default: 'lg:bg-white lg:rounded lg:shadow',
    },
  },
  data () {
    return {
      isOpen: false,
    };
  },
  computed: {
    openedClass () {
      return this.isOpen ? 'lg:translate-y-0 opacity-100' : 'lg:translate-y-4 opacity-0';
    },
    narrowClass () {
      return this.narrow ? '' : 'lg:min-w-xs';
    },
  },
  methods: {
    close () {
      this.isOpen = false;
    },
  },
};
</script>
