<template lang="pug">
  .relative(v-clickoutside='close' @keydown.esc='close' v-if="windowWidth > 1024")
    a(href="#" @click.prevent="isOpen = !isOpen" :class="targetCustomClasses")
      slot
    .transform.z-10.transition-all.ease-out.duration-300(
      ref="dropdown"
      :class="isOpen ? 'block lg:visible' : 'hidden lg:block lg:invisible'"
      class="lg:pt-2 lg:absolute lg:top-full lg:left-1/2 lg:-translate-x-1/2"
    )
      .transform.transition-all.ease-out.duration-300(
        :class="[openedClass, narrowClass, customClasses]"
      )
        slot(name='dropdown')
  .static(v-else)
    a(href="#" @click.prevent="isOpen = !isOpen" :class="targetCustomClasses")
      slot
    .mt-12(
      ref="dropdown"
      :class="isOpen ? 'block' : 'hidden'"
    )
      .transform.transition-all.ease-out.duration-300
        slot(name='dropdown')
</template>

<script>
export default {
  name: 'GenericSubNavRecast',
  props: {
    narrow: {
      type: Boolean,
      default: false,
    },
    targetCustomClasses: {
      type: String,
      default: '',
    },
    customClasses: {
      type: String,
      default: 'lg:bg-white lg:rounded lg:shadow',
    },
    menuKey: {
      type: String,
      default: 'return',
    },
    secondaryMobileMenuOpen: {
      type: Boolean,
      default: true,
    },  
  },
  data () {
    return {
      isOpen: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    openedClass () {
      return this.isOpen ? 'lg:translate-y-0 opacity-100' : 'lg:translate-y-4 opacity-0';
    },
    narrowClass () {
      return this.narrow ? '' : 'lg:min-w-xs';
    },
  },
  watch: {
    secondaryMobileMenuOpen () {
      if (this.isOpen && !this.secondaryMobileMenuOpen) this.isOpen = false;
    },
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy () { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    close () {
      this.isOpen = false;
    },
    onResize () {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>


<style>
.max-h-screen-20{
  max-height: calc(100vh - 5rem);
}
</style>
