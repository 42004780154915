<template lang="pug">
  component(
    :is="type"
    :class="disabledClasses"
    :disabled="disabled || loading"
    :href="href"
    :target="target"
    class="focus:outline-none transition duration-150 ease-in-out"
    @click="handleClick"
  )
    slot
    span(v-if="loading")
      .flex.animate-spin(class="ml-4")
        sw-icon(name='loading-spinner-gray' size='w-6 h-6' unstyled)
</template>

<script>
import { smoothScroller } from '../../mixins/smoothScroller';

export default {
  name: 'SwButton',
  mixins: [smoothScroller],
  props: {
    href: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    scrollTo: {
      type: String,
      default: '',
    },
    scrollOffset: {
      type: Number,
      default: 0,
    },
    target: {
      type: String,
      default: '_self',
    },
    turbolink: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type () {
      if (this.turbolink) return 'button';
      return this.href ? 'a' : 'button';
    },
    disabledClasses () {
      return this.disabled ? 'opacity-25 cursor-not-allowed' : '';
    },
  },
  methods: {
    scroll () {
      const elementToScroll = document.getElementById(this.scrollTo);

      if (elementToScroll) this.smoothScrollToElement(elementToScroll, this.scrollOffset);
    },
    handleClick () {
      this.scrollTo ? this.scroll() : this.$emit('click');
      if (this.turbolink) this.$turbolinks.visit(this.href);
    },
  },
};
</script>
