<script>
export default {
  name: 'RenderlessSmileNavigation',
  data () {
    return {
      mobileMenuOpen: false,
      secondaryMobileMenuOpen: false,
      connectionMenuOpen: false,
      hero: null,
      scrolled: false,
      scrolledPastHero: false,
      bannerVisible: true,
      windowWidth: window.innerWidth,
    };
  },
  watch: {
    mobileMenuOpen () {
      if (!this.mobileMenuOpen) this.secondaryMobileMenuOpen = false;
    },
    windowWidth () {
      if (this.windowWidth < 1024) {
        this.secondaryMobileMenuOpen = false;
        this.mobileMenuOpen = false;
      } 
    },
  },
  created () {
    this.trackScroll();

    window.addEventListener('scroll', this.trackScroll);
  },
  mounted () {
    this.hero = document.getElementById('hero');
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.trackScroll);
    window.removeEventListener('resize', this.onResize); 
  },  
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth;
    },
    trackScroll () {
      this.scrolled = window.pageYOffset !== 0;
      if (this.hero) this.scrolledPastHero = (window.scrollY > (this.hero.offsetTop + this.hero.offsetHeight));
    },
    toggleMobileMenu () {
      if (this.mobileMenuOpen) return this.mobileMenuOpen = false;
      
      setTimeout(() => { // We delay the opening of this menu (and the closing of the other) as this method will always be called twice at the same time: once by the user's click action, once by the v-clickoutside directive on the menu dropdown itself. The menu would otherwise never open up.
        this.mobileMenuOpen = true;
        this.connectionMenuOpen = false;
      });
    },
    toggleMobileSecondaryMenu () {
      if (this.windowWidth < 1024) {
        this.secondaryMobileMenuOpen = !this.secondaryMobileMenuOpen;
      } else this.secondaryMobileMenuOpen = false;
    },
    toggleConnectionMenu () {
      if (this.connectionMenuOpen) return this.connectionMenuOpen = false;

      setTimeout(() => { // We delay the opening of this menu (and the closing of the other) as this method will always be called twice at the same time: once by the user's click action, once by the v-clickoutside directive on the menu dropdown itself. The menu would otherwise never open up.
        this.connectionMenuOpen = true;
        this.mobileMenuOpen = false;
      });
    },
    handleMobileMenuClickoutside () {
      if (this.mobileMenuOpen) this.toggleMobileMenu();
    },
    handleConnectionMenuClickoutside () {
      if (this.connectionMenuOpen) this.toggleConnectionMenu();
    },
    hideBanner () {
      this.bannerVisible = false;
      window.bannerVisible = false;
    },
  },
  render () {
    return this.$scopedSlots.default({
      mobileMenuOpen: this.mobileMenuOpen,
      toggleMobileMenu: this.toggleMobileMenu,
      handleMobileMenuClickoutside: this.handleMobileMenuClickoutside,
      connectionMenuOpen: this.connectionMenuOpen,
      toggleConnectionMenu: this.toggleConnectionMenu,
      handleConnectionMenuClickoutside: this.handleConnectionMenuClickoutside,
      scrolled: this.scrolled,
      scrolledPastHero: this.scrolledPastHero,
      hideBanner: this.hideBanner,
      bannerVisible: this.bannerVisible,
      toggleMobileSecondaryMenu: this.toggleMobileSecondaryMenu,
      secondaryMobileMenuOpen: this.secondaryMobileMenuOpen,
    });
  },
};
</script>
